import { ActionTree, MutationTree, GetterTree } from "vuex";
import { queryFromObject } from "@/utils/api-functions";
import Vue from "vue";

export interface indexTimelinePayload {
  q?: any;
  s?: any;
  per_page?: number;
  seller_id?: number | boolean;
}

export interface CreateTimelinePayload {
  body: {
    note: string;
  };
  activity_id: number;
  type: "NOTE" | "EMAIL" | "PHONE" | "SELLER_NOTE" | "PARTNER_NOTE";
}
export interface PatchTimelinePayload {
  id: number;
  body?: {
    note: string;
  };
  activity_id?: number;
  type?: "NOTE" | "EMAIL" | "PHONE" | "SELLER_NOTE" | "PARTNER_NOTE";
}

export enum TimelineEntryType {
  message = "MESSAGE",
  general = "GENERAL",
  originChanged = "ORIGIN_CHANGED",
  activityCreated = "ACTIVITY_CREATED",
  bidUpdated = "BID_UPDATED",
  bidRejected = "BID_REJECTED",
  bidAccepted = "BID_ACCEPTED",
  documentAvailable = "DOCUMENT_AVAILABLE",
  signatureRequest = "SIGNATURE_REQUEST",
  taskCompleted = "TASK_COMPLETED",
  smallMilestone = "SMALL_MILESTONE",
  largeMilestone = "LARGE_MILESTONE",
  activityWorkStatusUpdated = "ACTIVITY_WORK_STATUS_UPDATED",
  note = "NOTE",
  sellerNote = "SELLER_NOTE",
  partnerNote = "PARTNER_NOTE",
  email = "EMAIL",
  phone = "PHONE",
  activityLost = "ACTIVITY_LOST",
  mandateCreated = "MANDATE_CREATED",
  mandateUpdated = "MANDATE_UPDATED",
  mandatedSigned = "MANDATE_SIGNED",
  salesPromiseEmpty = "SALES_PROMISE_EMPTY",
  appointmentCreated = "APPOINTMENT_CREATED",
  reviewPlaced = "REVIEW_PLACED",
}

interface TimelineState {
  all: TimelineItem[];
  checkUpdate: boolean;
  mentions: Mention[];
}

export interface Mention {
  id: number;
  first_name: string;
  last_name: string;
}

const initialState: TimelineState = {
  all: [],
  checkUpdate: false,
  mentions: [],
};

const getters: GetterTree<TimelineState, RootState> = {
  all(state: TimelineState): TimelineItem[] {
    return state.all;
  },
  notes(state: TimelineState): TimelineItem[] {
    return state.all.filter((item: TimelineItem) => [TimelineEntryType.note, TimelineEntryType.email, TimelineEntryType.partnerNote, TimelineEntryType.phone].includes(item.type));
  },
  partnerMentions(state: TimelineState): Mention[] {
    return state.mentions;
  },
};

const mutations: MutationTree<TimelineState> = {
  RESET(state: TimelineState) {
    state.all = [];
    state.checkUpdate = false;
  },
  ADD_MODEL(state: TimelineState, payload: TimelineItem) {
    state.all.unshift(payload);
  },
  SET_MODELS(state: TimelineState, payload: TimelineItem[]) {
    state.all = payload;
  },
  SET_MENTIONS(state: TimelineState, payload: Mention[]) {
    state.mentions = payload;
  },
  UPDATE_MODEL(state: TimelineState, payload: TimelineItem) {
    const index = state.all.findIndex((model: TimelineItem) => model.id === payload.id);

    if (index === -1) {
      state.all.push(payload);
    } else {
      Vue.set(state.all, index, payload);
    }
  },
  DELETE_MODEL(state: TimelineState, id: number) {
    // @ts-ignore
    const index = state.all.findIndex((model: TimelineItem) => model.id == id);

    if (index >= 0) {
      state.all.splice(index, 1);
    }
  },
  ADD_MODELS(state: TimelineState, payload: TimelineItem[]) {
    state.all = [...state.all, ...payload];
  },
  SET_CHECK_UPDATE(state: TimelineState, payload: boolean) {
    state.checkUpdate = payload;
  },
};

const actions: ActionTree<TimelineState, RootState> = {
  create({ commit, rootState }, payload: CreateTimelinePayload) {
    return rootState.api
      .post(`timeline-entries`, payload, { withCredentials: true })
      .then((response: { data: TimelineItem }) => {
        commit("ADD_MODEL", response.data);
        commit("SET_CHECK_UPDATE", false);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  patch({ commit, rootState }, payload: PatchTimelinePayload) {
    return rootState.api
      .patch(`timeline-entries/${payload.id}`, payload, { withCredentials: true })
      .then((response: { data: TimelineItem }) => {
        commit("UPDATE_MODEL", response.data);
        commit("SET_CHECK_UPDATE", false);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  delete({ commit, rootState }, payload: number) {
    return rootState.api
      .delete(`timeline-entries/${payload}`, { withCredentials: true })
      .then((response: { data: void }) => {
        commit("DELETE_MODEL", payload);
        commit("SET_CHECK_UPDATE", false);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  index({ commit, rootState }, payload: indexTimelinePayload) {
    let headers = {};
    if (payload.seller_id) {
      headers = { "x-seller-id": payload.seller_id };
    }

    return rootState.api
      .get(`timeline-entries?${queryFromObject(payload)}`, { withCredentials: true, headers })
      .then((response: { data: TimelineItem[]; total: number }) => {
        commit("SET_MODELS", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  getPartnerMentions({ commit, rootState }, activityId: number) {
    return rootState.api
      .get(`activities/${activityId}/mentions-for-partners`, { withCredentials: true })
      .then((response: { data: TimelineItem[]; total: number }) => {
        commit("SET_MENTIONS", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
