import Vue from "vue";
import "./sentry";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";

// Imports
import { i18n } from "@/localization";
import "lazysizes";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";

// Import Vue plugins
import VueLazyload from "vue-lazyload";
import TextareaAutosize from "vue-textarea-autosize";
import PortalVue from "portal-vue";
import VueToast from "vue-toast-notification";
import vueCountryRegionSelect from "vue-country-region-select";
import VAnimateCss from "v-animate-css";
import FloatingVue from "floating-vue";
import VueCookies from "vue-cookies";
import NavScroll from "navscroll";
import vClickOutside from "v-click-outside";
import VueClipboard from "vue-clipboard2";
import autoAnimate from "@formkit/auto-animate";
import ReadMore from "vue-read-more";
import vuescroll from "vue-scroll";
// CSS
import "vue-toast-notification/dist/theme-sugar.css";
import "@/assets/scss/app.scss";
import "@/assets/svg/index";
import "floating-vue/dist/style.css";
import "vue-select/dist/vue-select.css";

// Vue configuration
Vue.use(NavScroll);
Vue.use(vueCountryRegionSelect);
Vue.use(VueToast, {
  position: window.outerWidth >= 1024 ? "bottom-right" : "top",
});
Vue.use(PortalVue);
Vue.use(VueLazyload);
Vue.use(TextareaAutosize);
Vue.use(VAnimateCss);
Vue.use(FloatingVue);
Vue.use(VueCookies);
Vue.use(vClickOutside);
Vue.use(VueClipboard);
Vue.use(ReadMore);
Vue.use(vuescroll);

Vue.directive("auto-animate", {
  inserted: function (el, binding) {
    autoAnimate(el, binding.value);
  },
});

Vue.config.productionTip = false;

Vue.prototype.$errorResponse = {
  status: 0,
  errors: [],
};

NavScroll.setDefaults({
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  cancelable: true,
  onDone: false,
  onCancel: false,
  scrollX: false,
  scrollY: true,
});

// Create Vue event bus
export const bus = new Vue();

new Vue({
  router,
  i18n,
  store,

  render: (h) => h(App),

  data: () => ({
    breakpoints: ["xs"],
    version: "v1.14.10",
  }),

  mounted() {
    // @ts-ignore
    this.init();

    // @ts-ignore
    window.addEventListener("resize", () => this.init());

    // @ts-ignore
    window.addEventListener("orientationchange", () => this.init());
  },

  methods: {
    init() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);

      if (window.innerWidth < 576) {
        this.breakpoints = ["xs"];
      }
      if (window.innerWidth >= 576) {
        this.breakpoints = ["xs", "sm"];
      }
      if (window.innerWidth >= 768) {
        this.breakpoints = ["xs", "sm", "md"];
      }
      if (window.innerWidth >= 1024) {
        this.breakpoints = ["xs", "sm", "md", "lg"];
      }
      if (window.innerWidth >= 1280) {
        this.breakpoints = ["xs", "sm", "md", "lg", "xl"];
      }
      if (window.innerWidth >= 1536) {
        this.breakpoints = ["xs", "sm", "md", "lg", "xl", "2xl"];
      }
      if (window.innerWidth >= 1800) {
        this.breakpoints = ["xs", "sm", "md", "lg", "xl", "2xl", "3xl"];
      }
      if (window.innerWidth >= 2100) {
        this.breakpoints = ["xs", "sm", "md", "lg", "xl", "2xl", "3xl", "4xl"];
      }
    },
  },
  computed: {
    mediaQuery() {
      if (this.breakpoints.includes("4xl")) {
        return "4xl";
      } else if (this.breakpoints.includes("3xl")) {
        return "3xl";
      } else if (this.breakpoints.includes("2xl")) {
        return "2xl";
      } else if (this.breakpoints.includes("xl")) {
        return "xl";
      } else if (this.breakpoints.includes("lg")) {
        return "lg";
      } else if (this.breakpoints.includes("md")) {
        return "md";
      } else if (this.breakpoints.includes("sm")) {
        return "sm";
      } else if (this.breakpoints.includes("xs")) {
        return "xs";
      }
    },
    isMobile() {
      return this.breakpoints.includes("xs") && !this.breakpoints.includes("md") && !this.breakpoints.includes("xl");
    },
    isTablet() {
      return this.breakpoints.includes("xs") && !this.breakpoints.includes("lg");
    },
    isDesktop() {
      return this.breakpoints.includes("xl");
    },
    isFullScreenApp() {
      // @ts-ignore
      return window.navigator.standalone;
    },
    isProd() {
      return process.env.NODE_ENV === "production";
    },
  },
}).$mount("#app");
